<template>
  <v-app v-bind="chatLayoutAppBindings">
    <ClientOnly>
      <v-app-bar v-if="showAppBar" app>
        <v-avatar size="small" class="ms-4" tile>
          <v-img :src="iconColor" />
        </v-avatar>
        <v-toolbar-title class="m-font-heading">
          {{ title }}
        </v-toolbar-title>
        <ClientOnly>
          <v-toolbar-items v-if="hydrated">
            <v-btn
              v-if="identified"
              icon="mdi-pencil-box-outline"
              color="primary"
              @click="showStartChat = !showStartChat"
            />
            <MalarkeyToolbarUserMenu :show-login="false" />
          </v-toolbar-items>
        </ClientOnly>
      </v-app-bar>
      <v-app-bar v-if="showConversationAppBar" app density="compact">
        <v-toolbar-items>
          <ClientOnly>
            <v-btn v-if="hydrated" icon color="primary" :to="{ name: 'index' }">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </ClientOnly>
        </v-toolbar-items>
        <v-spacer />
        <ClientOnly>
          <v-toolbar-items v-if="hydrated">
            <MalarkeyToolbarUserMenu :show-login="false" />
          </v-toolbar-items>
        </ClientOnly>
      </v-app-bar>
      <v-navigation-drawer
        v-if="identified && smAndUp"
        app
        location="start"
        permanent
      >
        <MalarkeyConversationList v-model="selected" class="py-0" />
      </v-navigation-drawer>
    </ClientOnly>
    <v-main>
      <slot />
    </v-main>
    <ClientOnly>
      <v-dialog v-model="showStartChat" v-bind="startChatDialogBindings">
        <MalarkeyStartChat
          v-if="showStartChat"
          @sid="onSid"
          @cancel="showStartChat = false"
        />
      </v-dialog>
    </ClientOnly>
    <ClientOnly>
      <v-snackbar
        :close-on-back="false"
        :close-on-content-click="false"
        color="background"
        location="top center"
        :model-value="canRequestPush"
        :timeout="-1"
        position="fixed"
      >
        <span>Click here to enable Push Notifications</span>
        <template #actions>
          <v-btn
            size="small"
            color="success"
            class="me-2"
            variant="elevated"
            @click="doOnRequestPush"
            >Enable</v-btn
          >
          <v-btn
            size="small"
            color="error"
            variant="elevated"
            @click="doOnDismissPush"
            >Dismiss</v-btn
          >
        </template>
      </v-snackbar>
    </ClientOnly>
  </v-app>
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  ref,
  inject,
  onMounted,
  onBeforeUnmount,
  watch,
  nextTick,
} from "vue";
import { useVueprint, getBootStatuses } from "@jakguru/vueprint/utilities";
import { useDisplay } from "vuetify";

import type {
  IdentityService,
  SwalService,
  PushService,
  ToastService,
  BusService,
  LocalStorageService,
} from "@jakguru/vueprint";

export default defineComponent({
  name: "MalarkeyWebAppLayout",
  setup() {
    const { mounted, booted, ready } = useVueprint();
    const { afterEach } = useRouter();
    const { finish } = useLoadingIndicator();
    const { smAndUp } = useDisplay();
    const locallyMounted = ref(false);
    const malarkey = useUseMalarkeyConfig();
    const iconColor = computed(() => malarkey.visualFiles.iconColor);
    const title = computed(() => malarkey.customizations.app.name);
    const showStartChat = ref(false);
    const route = useRoute();
    const startChatDialogBindings = computed(() => ({
      closeOnBack: false,
      closeOnContentClick: false,
      contained: true,
      maxWidth: 400,
      retainFocus: false,
      persistent: true,
    }));
    const bus = inject<BusService>("bus");
    const ls = inject<LocalStorageService>("ls");
    const triggerWindowResized = () => {
      nextTick(() => {
        if (bus) {
          bus.emit("window:resized", { local: true });
        }
      });
    };
    const selected = computed({
      get() {
        return route.params.sid || null;
      },
      set(sid) {
        navigateTo({ name: "conversations-sid", params: { sid } });
      },
    });
    const onSid = (sid: string) => {
      navigateTo({ name: "conversations-sid", params: { sid } });
      showStartChat.value = false;
    };
    const identity = inject<IdentityService>("identity");
    const identified = computed(() => {
      if (!booted.value || !identity) {
        return false;
      }
      return identity.identified.value === true;
    });
    const hydrated = computed(
      () =>
        locallyMounted.value &&
        mounted.value &&
        booted.value &&
        ready.value &&
        import.meta.browser,
    );
    const showAppBar = computed(
      () =>
        (route.name !== "conversations-sid" &&
          identified.value &&
          hydrated.value) ||
        smAndUp.value,
    );
    const showConversationAppBar = computed(
      () =>
        route.name === "conversations-sid" &&
        identified.value &&
        hydrated.value &&
        !smAndUp.value,
    );
    onMounted(() => {
      locallyMounted.value = true;
      if (bus) {
        bus.on("layout:updated", triggerWindowResized, { local: true });
      }
    });
    onBeforeUnmount(() => {
      locallyMounted.value = false;
      if (bus) {
        bus.off("layout:updated", triggerWindowResized);
      }
    });
    watch(
      () => identified.value,
      (is) => {
        if (!is) {
          showStartChat.value = false;
          if (ls) {
            const lastLoginType = ls.get("loginType");
            if ("agent" === lastLoginType) {
              navigateTo({ name: "login-agent" });
            }
          }
        }
      },
      { immediate: true },
    );
    afterEach(() => {
      finish();
    });
    const swal = inject<SwalService>("swal");
    const toast = inject<ToastService>("toast");
    const onReadyOrCompleteClick = () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const status: any = getBootStatuses();
      status.mounted = mounted.value;
      status.booted = booted.value;
      status.ready = ready.value;
      console.log(status);
      if (swal) {
        swal.fire({
          title: "Boot Statuses",
          text: JSON.stringify(status, null, 2),
        });
      }
    };
    const push = inject<PushService>("push");
    const canRequestPush = computed(() => {
      if (!mounted.value || !ready.value || !push) {
        return false;
      }
      return push.canRequestPermission.value;
    });

    const doOnRequestPush = () => {
      if (push) {
        push.requestPushPermission();
      }
    };
    const doOnRejectPush = () => {
      if (push) {
        push.doNotRequestPushPermission();
      }
    };
    const doOnDismissPush = async () => {
      if (!swal) {
        return;
      }
      const { isConfirmed } = await swal.fire({
        icon: "question",
        title: "Permenantly Dismiss",
        text: "Do you want to permenantly dismiss push notifications? You will not be asked again in this browser session.",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (isConfirmed) {
        doOnRejectPush();
        if (toast) {
          toast.fire({
            icon: "success",
            title: "Push Notifications Permenantly Dismissed",
          });
        }
      }
    };
    const chatLayoutAppBindings = computed(() => ({
      id: "chat-layout",
    }));

    return {
      iconColor,
      title,
      showStartChat,
      startChatDialogBindings,
      onSid,
      identified,
      hydrated,
      onReadyOrCompleteClick,
      selected,
      canRequestPush,
      doOnRequestPush,
      doOnDismissPush,
      smAndUp,
      showAppBar,
      showConversationAppBar,
      chatLayoutAppBindings,
    };
  },
});
</script>

<style lang="scss">
#chat-layout {
  > .v-application__wrap {
    > header.v-toolbar.v-app-bar {
      top: var(--lh-window-visual-viewport-page-top, 0) !important;
    }
  }
}
</style>
